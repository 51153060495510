/* @font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Ultalight.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Ultalight.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Light.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Light.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Thin.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Thin.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Regular.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-SemiBold.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-SemiBold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Bold.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Bold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Heavy.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Heavy.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Black.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Black.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 800;
} */

body,
html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--theme-text);
  font-weight: 500;
  font-size: 0.9375rem;
  overscroll-behavior: none;
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: var(--text-main);
  /* font-family: "gilroy", sans-serif !important; */
  font-family: Helvetica, sans-serif;
}

button {
  font-family: inherit; /* Add this line */
}

body,
input,
textarea,
button {
  font-family: Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-moz-selection {
  /* Code for Firefox */
  background: var(--primary-green);
}

::selection {
  background: var(--primary-green);
}

:root {
  --primary-border: rgba(0, 0, 0, 0.1);
  --primary-bg: #f3f3e9;

  --primary-green: #90f188;

  --primary-purple: #b488f1;
  --theme-color: #b488f1;

  --box-shadow-1: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  --text-main: #0f1d0a;
  --text-body: rgba(0, 0, 0, 0.5);
  --theme-text: rgba(15, 29, 7, 0.7);

  --theme-text: #0f1d0a;

  --max-width: 1140px;

  --container-gutter: clamp(2.5rem, 2.6455026455vw, 3.325rem);
}

@media (max-width: 768px) {
  :root {
    --container-gutter: 20px;
  }

  body,
  html {
    overflow-x: hidden;
  }
}

*,
:after,
:before {
  box-sizing: inherit;
}
