.col {
  display: flex;
  flex-direction: column;
  gap: clamp(1.875rem, 1.9841269841vw, 2.49375rem);
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}

.row {
  display: flex;
  flex-direction: row;
  gap: clamp(1.875rem, 1.9841269841vw, 2.49375rem);
}

.showReel {
  display: flex;
  align-items: end;
  border-radius: 30px;
  position: relative;
  height: 100vh;
  width: 100%;
  box-shadow: var(--box-shadow-1);
  background-color: var(--text-main);
}

.showReel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/colors_of_summer_2.jpeg"); */
  background-size: cover;
  filter: brightness(0.7);
  border-radius: 30px;
  z-index: -1;
  /* Apply translateY transform based on backgroundY */
}

.showReelInner {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 20;
  padding: clamp(1.5rem, 1.5873015873vw, 1.995rem)
    clamp(1.875rem, 1.9841269841vw, 2.49375rem);
}

.text {
  color: var(--primary-green);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.embed {
  position: absolute;
  border-radius: 30px;
  overflow: hidden;
  z-index: 15;
  /* aspect-ratio: 16 / 10; */
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: var(--box-shadow-1);
}

.embedMobile {
  position: absolute;
  border-radius: 30px;
  overflow: hidden;
  z-index: 15;
  width: 38.0281690141%;
  aspect-ratio: 9 / 19.5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: var(--box-shadow-1);
}

@media (max-width: 768px) {
  .embed {
    width: 100%;
    height: 100%;
  }

  .showReelInner {
    display: none;
  }
}
