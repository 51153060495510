.grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  padding-left: var(--container-gutter);
  gap: clamp(5.9375rem, 6.2830687831vw, 7.896875rem)
    clamp(5rem, 5.291005291vw, 6.65rem);
  justify-content: space-between;

  padding-right: var(--container-gutter);
  max-width: clamp(68.125rem, 72.0899470899vw, 90.60625rem);
}
.offering {
  display: grid;
  gap: clamp(2.125rem, 2.2486772487vw, 2.82625rem);
  align-content: flex-start;
}

.title {
  margin: 0;
  font-size: clamp(1.875rem, 1.9841269841vw, 2.49375rem);
}

.stats {
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: clamp(1.25rem, 1.3227513228vw, 1.6625rem);
  gap: 0.8em;
  color: var(--text-body);
  font-weight: 500;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 3.125rem;
  }

  .title {
    font-size: clamp(1.5rem, 1.5873015873vw, 1.995rem);
  }

  .stats {
    font-size: clamp(1.125rem, 1.1904761905vw, 1.49625rem);
  }
}
