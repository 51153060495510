.pageWrapper {
  display: grid;
  z-index: 1;
  align-items: flex-start;
  min-height: 100vh; /* Ensure page fills screen height */
}

.contentWrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center; /* Center content horizontally */
}

.bg {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 100%;
  z-index: 0;
  background: var(--primary-bg);
  padding-bottom: 6rem;
}

@media (max-width: 768px) {
  .pageWrapper {
    display: block;
  }
  .bg {
    padding-bottom: 4rem;
  }
}
