/* Footer component styles */
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 2rem;
}

.icon {
  color: var(--primary-green);
}

.footerInner {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 3rem;
}

.bigText {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0px;
  font-size: 70px;
  line-height: 95%;
  font-weight: 500;
}

.bigText1 {
  column-gap: 0px;
  font-size: 70px;
  line-height: 95%;
  font-weight: 500;
}

.icon {
  transition: all 0.3s ease-in-out;
}

.color {
  color: var(--primary-green);
  transition: all 0.3s ease-in-out;
}

.marginLeft {
  margin-left: 20px;
  transition: all 0.3s ease-in-out;
}

.contact {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  margin-bottom: 3rem;
}

.boldText {
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.muteText {
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  margin-top: 0rem;
  color: var(--text-body);
  margin-bottom: 0rem;
}

.copyRight {
  border-top: 1px solid var(--primary-green);
  padding-top: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.underLine {
  transition: all 0.3s ease-in-out;
}

.underLine:hover {
  text-decoration: underline;
  cursor: pointer;
}

.star {
  color: var(--primary-green);
  height: 60%;
}

.link {
  color: var(--text-main);
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  display: flex;
  border-radius: 30px;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}

.link:hover {
  background: var(--text-main);
  color: var(--primary-green);
}

/* Animation for rotatingText */
.rotatingText {
  display: inline-block; /* Ensure it's treated as a block element */
}

@media (max-width: 768px) {
  .footer {
    padding-top: 2rem;
    padding-bottom: 5rem;
  }

  .grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
  }
  .bigText {
    font-size: 2.5rem;
  }

  .bigText1 {
    font-size: 2.5rem;
  }
}
