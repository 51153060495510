.header {
  display: flex;
  align-items: center;
  height: 5.3125rem;
  z-index: 1;
  background-color: var(--primary-bg);
}

.logo {
  width: 50px;
  height: 50px;
  margin-left: var(--container-gutter);
}

.menu {
  width: fit-content;
  padding: 0.4375rem;
  background-color: hsla(0, 0%, 100%, 0.7);
  border-radius: 100vh;
  position: fixed;
  top: 1.25rem;
  bottom: auto;
  z-index: 101;
  left: 50%;
  transform: translateX(-50%);
}

.menuItems {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  position: relative;
}

.bg {
  position: absolute;
  height: 100%;
  background: var(--text-main);
  border-radius: 30px;
  z-index: -1;
}

.hoverBg {
  position: absolute;
  height: 100%;
  background: #d2d2cd8f;
  border-radius: 30px;
  z-index: -2;
}

.item {
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  display: flex;
  border-radius: 30px;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  z-index: 1;
}

.itemActive {
  color: var(--primary-green);
}

@media (max-width: 768px) {
  .menu {
    bottom: 1.25rem;
    top: auto;
  }
}
