.hero {
  min-height: 80vh;
  display: flex;
  align-items: center;

  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}

.heroText {
  font-size: clamp(8.125rem, 8.5978835979vw, 10.80625rem);
  margin-top: 0;
  font-weight: 600;
  line-height: 0.95;

  margin-bottom: 0;
}

@media (max-width: 768px) {
  .heroText {
    font-size: clamp(3.125rem, 6.5104166667vw, 4.15625rem);
  }

  .hero {
    min-height: 60vh;
  }
}
