.main {
  display: grid;
  flex-direction: column;
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
  grid-template-areas:
    "content image"
    "stats image"
    "logos logos";
  gap: clamp(3.125rem, 3.3068783069vw, 4.15625rem);
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto auto 1fr;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 5rem;
}

.leftSection {
  display: flex;
  flex-direction: column;
  row-gap: 10rem;
}

.textSection {
  grid-area: content;

  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.pText {
  color: var(--text-main);
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: clamp(1.25rem, 1.3227513228vw, 1.6625rem);
  line-height: 150%;
}

.button {
  padding: 0.6666666667em 0.8em;
  font-size: 1rem;
  display: flex;
  width: fit-content;
  background-color: var(--text-main);
  color: white;
  border: none;
  border-radius: 30px;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  gap: 0.5rem;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: var(--primary-green);
  color: var(--text-main);
}

.button:hover .arrow {
  color: var(--text-main) !important;
}

.arrow {
  color: var(--primary-green) !important;
  width: 25px;
  height: 25px;
}

.rightSection {
  max-width: 100%;
  grid-area: image;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.valueSection {
  grid-area: stats;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  border-bottom: 1px solid var(--primary-border);
  padding-bottom: 1.5rem;
}

.logos {
  grid-area: logos;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 3rem;
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}

.logo {
  max-width: 150px;
  object-fit: contain;
  filter: brightness(0);
}

.valueNum {
  display: flex;
  flex-direction: row;
  font-size: clamp(5rem, 5.291005291vw, 6.65rem);
}

.number {
  font-size: clamp(5rem, 5.291005291vw, 6.65rem);
}

.valueText {
  font-size: clamp(1.75rem, 1.8518518519vw, 2.3275rem);
}

@media (max-width: 768px) {
  .main {
    grid-template-areas:
      "content content"
      "image image"
      "stats stats"
      "logos logos";
  }

  .valueNum {
    font-size: clamp(3.125rem, 5.0403225806vw, 4.15625rem);
  }

  .number {
    font-size: clamp(3.125rem, 5.0403225806vw, 4.15625rem);
  }

  .valueText {
    font-size: clamp(1.5rem, 2.4193548387vw, 1.995rem);
  }

  .logos {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    padding-left: 0;
    padding-right: 0;
  }

  .logo {
    max-width: 100px;
    max-height: 50px;
    margin: auto;
    position: relative;
    width: 100%;
  }

  .logo::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.8;
    z-index: 1;
  }

  .logo img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    z-index: 2;
}
}
