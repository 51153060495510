.showReel {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.showReel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/ben_nyc.jpg");
  background-size: cover;
  filter: brightness(0.7);
  z-index: -1;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius, 0); /* Add this line */
}

.showReelInner {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 20;
  padding: clamp(1.5rem, 1.5873015873vw, 1.995rem)
    clamp(1.875rem, 1.9841269841vw, 2.49375rem);
}

.text {
  color: var(--primary-green);
  font-size: 20px;
  font-weight: 600;
}

.img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  max-width: 69%;
  max-height: 80%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .showReel {
    height: 50vh;
  }

  .showReel::before {
    background-image: url("https://storage.googleapis.com/kashew_static/DSC03056-removebg.png");
    background-position: center;
    filter: none;
  }

  .img {
    display: none;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .text {
    margin: 0;
    color: white;
    font-weight: 400;
  }
}
