.textSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: clamp(7.5rem, 15.625vw, 9.975rem) auto;
  max-width: 80%;
}

.h2Text {
  margin-top: 0;
  font-size: clamp(6.25rem, 6.6137566138vw, 8.3125rem);
  color: white;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 0.95;
}

@media (max-width: 768px) {
  .h2Text {
    font-size: clamp(2.1875rem, 4.5572916667vw, 2.909375rem);
    line-height: 1;
  }
}
