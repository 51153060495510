.textSection {
  display: flex;
  align-items: center;
  margin: clamp(7.5rem, 15.625vw, 9.975rem) auto;
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}

.h2Text {
  margin-top: 0;
  font-size: clamp(3.75rem, 3.9682539683vw, 4.9875rem);
  color: var(--text-main);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}
.color {
  color: var(--primary-green);
  font-weight: 600;
}

@media (max-width: 768px) {
  .h2Text {
    font-size: clamp(1.875rem, 3.90625vw, 2.49375rem);
  }
}
