.textSection {
  display: flex;
  align-items: center;
  margin: clamp(7.5rem, 15.625vw, 9.975rem) auto;
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}

.h2Text {
  margin-top: 0;
  font-size: clamp(6.25rem, 6.6137566138vw, 8.3125rem);
  color: var(--text-main);
  font-weight: 600;
  line-height: 0.95;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .h2Text {
    font-size: clamp(2.5rem, 8.3333333333vw, 3.325rem);
  }

  .textSection {
    margin-bottom: 2rem;
    margin-top: clamp(5rem, 5.291005291vw, 6.65rem) auto;
  }
}
